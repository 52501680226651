<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card v-if="handler" style="margin: -30px -20px">
          <div class="profile-tab-container">
            <div class="calendar-basic-info">
              <div class="date_event">
                <div class="calendar_head">
                  {{
                    getMonthData(calendarNepMonth)
                      ? getMonthData(calendarNepMonth).eng_month
                      : ""
                  }}, {{ calendarEngYear }} ({{ calendarNepYear }}
                  {{
                    getMonthData(calendarNepMonth)
                      ? getMonthData(calendarNepMonth).nep_month
                      : ""
                  }})
                </div>
                <span class="today_date_event"
                  >Today : {{ date.format("mmmm d, yyyy ddd") }}</span
                >
                <div class="date_controller">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="previousMonth()"
                    :class="yearEndClassPrevious"
                  >
                    <v-icon class="arrow"> arrow_back_ios </v-icon>
                  </a>
                  <a
                    href="javascript:void(0)"
                    @click.prevent="nextMonth()"
                    :class="yearEndClassNext"
                  >
                    <v-icon class="arrow"> arrow_forward_ios </v-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <div class="calendar-container">
          <v-card-title class="head_title">
            <div class="data-represent">
              <div>
                <span class="d-success"></span> &nbsp;
                <strong>{{ 0 }}</strong>
                <small> Exam</small>
              </div>

              <div>
                <span class="d-warning"></span> &nbsp;
                <!-- <strong>{{ totalEventCount }}</strong> -->
                <strong>{{ 0 }}</strong>
                <small> Event</small>
              </div>

              <div>
                <span class="d-primary"></span> &nbsp; <strong> </strong>
                <small> Today</small>
              </div>

              <div>
                <span class="d-error"></span> &nbsp;
                <strong>
                  {{ 0 }}
                </strong>
                <small> School Holiday</small>
              </div>
            </div>
          </v-card-title>
          <table class="table">
            <thead>
              <tr>
                <th v-for="(header, index) in headers" :key="index">
                  {{ header.name.capitalizeFirstLetter() }}
                  <p class="header_nepali">({{ header.nep_day }})</p>
                </th>
              </tr>
            </thead>
            <tbody v-if="events != null">
              <tr v-for="(value, index) in events" :key="index">
                <td
                  v-for="(v, i) in value"
                  :key="i"
                  :class="
                    v.is_holiday == 1 && v.disable_flag == 1
                      ? 'disable_day_saturday'
                      : v.disable_flag == 1
                      ? 'disable_day'
                      : v.is_holiday == 1
                      ? 'saturday'
                      : ''
                  "
                  @click="
                    v.disable_flag != 1 ? (eventDialog = true) : '',
                      (form.day_id = v.id)
                  "
                >
                  <!-- {{v.events}} -->
                  <span class="event_title" v-if="v.calendar_event != '--'">
                    <!-- {{ v.calendar_event.wordLength(v.calendar_event, 100) }} -->
                    {{ v.calendar_event }}
                  </span>
                  <!-- {{typeof v.events}} -->
                  <span
                    class="school_event"
                    v-if="v.events !== undefined || v.events !== null"
                  >
                    <span v-for="(school_event, ind) in v.events" :key="ind">
                      <!-- {{ school_event.event.title }} -->
                      title
                      <br />
                    </span>
                  </span>
                  <span class="nepDate">
                    {{ v.day_bs }}
                  </span>
                  <span class="engMonth">
                    {{ v.day_ad }}
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">
                  <not-found></not-found>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog v-model="eventDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add/Update</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-3">
          <v-form
            ref="form"
            @keydown.native="form.errors.clear($event.target.name)"
            lazy-validation
          >
            <v-text-field
              outlined
              dense
              required
              autocomplete="off"
              label="Event Title*"
              class="pa-0"
              v-model="form.event_title"
              name="event_title"
              :error-messages="form.errors.get('event_title')"
              :rules="[(v) => !!v || 'Title is required']"
            />
            <v-checkbox style="margin-top: 0" color="blue" v-model="form.range">
              <template v-slot:label>
                <div style="color: #333">
                  Date Range
                  <p style="line-height: 10px">
                    <small style="color: #666; font-size: 10px">
                      Check if event is for multiple days in a row.
                    </small>
                  </p>
                </div>
              </template>
            </v-checkbox>
            <v-calendar-field
              id-val="from_date"
              v-model="form.event_date"
              required
              name="event_date"
              :label="dateEnable ? 'Event Date' : 'From Date'"
              :error-messages="form.errors.get('event_date')"
              :rules="[(v) => !!v || 'Event is required']"
            >
            </v-calendar-field>
            <br />
            <v-calendar-field
              v-if="!dateEnable"
              id-val="to_date"
              v-model="form.to_date"
              label="To Date*"
            >
            </v-calendar-field>
            <v-checkbox color="#ff5252" v-model="form.is_holiday">
              <template v-slot:label>
                <div style="color: #333">
                  <span style="color: #ff5252">Mark as school holiday</span>
                  <p style="line-height: 10px">
                    <small style="color: #666; font-size: 10px">
                      Check if the above selected event is a school holiday.
                    </small>
                  </p>
                </div>
              </template>
            </v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="(eventDialog = false), form.reset()"
            >Close</v-btn
          >
          <v-btn color="success" text @click="save()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Form from "@/library/Form";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();
export default {
  data: () => ({
    props: {
      handler: {
        default: true,
        type: Boolean,
      },
    },
    form: new Form(
      {
        event_title: "",
        event_date: "",
        is_holiday: 0,
        range: 0,
        to_date: "",
        day_id: "",
      },
      "/api/events"
    ),
    dateEnable: true,
    eventDialog: false,
    handler: true,
    date: nd,
    yearEndClassNext: "",
    yearEndClassPrevious: "",
    calendarNepMonth: "",
    calendarNepYear: "",
    calendarEngYear: "",
    calendarEngMonth: "",
    engMonths: [
      { month: 1, name: "Jan" },
      { month: 2, name: "Feb" },
      { month: 3, name: "Mar" },
      { month: 4, name: "Apr" },
      { month: 5, name: "May" },
      { month: 6, name: "Jun" },
      { month: 7, name: "Jul" },
      { month: 8, name: "Aug" },
      { month: 9, name: "Sep" },
      { month: 10, name: "Oct" },
      { month: 11, name: "Nov" },
      { month: 12, name: "Dec" },
    ],
    headers: [
      { name: "sunday", nep_day: "आइत ", day: "1" },
      { name: "monday", nep_day: "सोम", day: "2" },
      { name: "tuesday", nep_day: "मंगल", day: "3" },
      { name: "wednesday", nep_day: "बुध", day: "4" },
      { name: "thursday", nep_day: "बिहि", day: "5" },
      { name: "friday", nep_day: "शुक्र", day: "6" },
      { name: "saturday", nep_day: "शनि", day: "7" },
    ],
    months: [
      {
        month: "01",
        nep_month: "वैशाख",
        nep_month_eng: "Baishak",
        eng_month: "Apr/May",
      },
      {
        month: "02",
        nep_month: "जेठ",
        nep_month_eng: "Jestha",
        eng_month: "May/Jun",
      },
      {
        month: "03",
        nep_month: "असार",
        nep_month_eng: "Ashad",
        eng_month: "Jun/Jul",
      },
      {
        month: "04",
        nep_month: "साउन",
        nep_month_eng: "Shrwan",
        eng_month: "Jul/Aug",
      },
      {
        month: "05",
        nep_month: "भदौ",
        nep_month_eng: "Bhadra",
        eng_month: "Aug/Sep",
      },
      {
        month: "06",
        nep_month: "असोज",
        nep_month_eng: "Ashoj",
        eng_month: "Sep/Oct",
      },
      {
        month: "07",
        nep_month: "कार्तिक",
        nep_month_eng: "Kartik",
        eng_month: "Oct/Nov",
      },
      {
        month: "08",
        nep_month: "मंसिर",
        nep_month_eng: "Mangsir",
        eng_month: "Nov/Dec",
      },
      {
        month: "09",
        nep_month: "पुष",
        nep_month_eng: "Poush",
        eng_month: "Dec/Jan",
      },
      {
        month: "10",
        nep_month: "माघ",
        nep_month_eng: "Magh",
        eng_month: "Jan/Feb",
      },
      {
        month: "11",
        nep_month: "फागुन",
        nep_month_eng: "Falgun",
        eng_month: "Feb/Mar",
      },
      {
        month: "12",
        nep_month: "चैत",
        nep_month_eng: "Chaitra",
        eng_month: "Mar/Apr",
      },
    ],
    events: null,
  }),
  mounted() {
    this.calendarNepMonth = nd.format("MM");
    this.calendarNepYear = nd.format("YYYY");
    this.calendarEngMonth = new Date().getMonth().pad();
    this.calendarEngYear = new Date().getFullYear();
    this.getevent();
  },
  watch: {
    "form.range": function (v) {
      this.dateEnable = true;
      this.form.to_date = "";
      if (v) {
        this.dateEnable = false;
        this.form.to_date = this.form.event_date;
      }
    },
  },
  methods: {
    getevent() {
      this.$rest
        .get(
          "/api/events?month=" +
            this.calendarNepMonth +
            "&&year=" +
            this.calendarNepYear
        )
        .then((res) => {
          this.events = res.data;
        });
    },
    save() {
      if (
        this.form.range == true &&
        (this.form.event_date > this.form.to_date ||
          this.form.event_date == this.form.to_date)
      ) {
        return this.$events.fire("notification", {
          message: "Events can only be set on following dates",
          status: "error",
        });
      }
      if (
        this.form.range == true &&
        this.form.event_date == "" &&
        this.form.to_date == ""
      ) {
        return this.$events.fire("notification", {
          message: "Both From and To Date is required on selecting date range",
          status: "error",
        });
      }
      this.form.fireFetch = false;
      if (this.$refs.form.validate()) {
        this.form.store().then((res) => {
          this.eventDialog = false;
          this.$refs.form.reset();
          this.getevent();
        });
      }
    },
    update() {
      this.$rest.put("/api/events/");
    },
    getEngMonth(month) {
      let monthName = this.engMonths.find((res) => res.month === month);
      return monthName.name;
    },
    getMonthData(month) {
      return this.months.find((res) => res.month === month);
    },
    nextMonth() {
      this.loading = true;
      this.calendarNepMonth = (parseInt(this.calendarNepMonth) + 1).pad();

      if (this.calendarNepMonth === "13") {
        this.calendarNepMonth = "01";
        this.calendarNepYear = parseInt(this.calendarNepYear) + 1;
      }
      this.getevent();
    },
    previousMonth() {
      this.loading = true;

      this.calendarNepMonth = (parseInt(this.calendarNepMonth) - 1).pad();
      if (this.calendarNepMonth === "00") {
        this.calendarNepMonth = "12";
        this.calendarNepYear = parseInt(this.calendarNepYear) - 1;
      }
      this.getevent();
    },
  },
};
</script>
<style lang="scss" scoped>
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: none;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
// .disable_day{
//   color: #cbd2d9;
// }
.today_date_event {
  font-weight: bold;
  color: #333;
}

.date_controller {
  margin-top: 15px;

  a {
    text-align: center;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    display: inline-block;
    font-size: 10px !important;
    background-color: white;
    padding: 4px 15px;
    border-radius: 3px;
    font-weight: bold;
    color: #1976d2 !important;

    span {
      font-weight: bold;
      color: #1976d2 !important;
    }

    text-decoration: none !important;
    margin-left: 10px;

    i {
      font-size: 14px;
    }
  }
}

.calendar-basic-info {
  width: 90%;
  margin: 20px auto 0 auto;
  padding: 15px;
  display: flex;
  justify-content: space-between;

  .calendar_head {
    font-size: 21px;
    font-weight: bold;
    color: white;
  }
}

.saturday:not(th):not(.current_day):not(.school_holiday) {
  color: #ff5252 !important;

  &:hover {
    color: #ff5252 !important;
  }
}
.disable_day {
  color: #eee !important;
}
.disable_day_saturday {
  color: #f9d6a9 !important;
  background: rgba(255, 255, 255, 0.5);
}
.current_day {
  background-color: #b5d9fd !important;
  color: #444 !important;
}

.school_holiday {
  color: white !important;
}

.header_nepali {
  font-size: 14px;
  color: #888;
  margin-bottom: 5px !important;
}

.td_cursor {
  cursor: pointer !important;
}

.td_cursor:not(.school_holiday):not(.current_day):not(.school_event_box):not(
    .public_holiday
  ) {
  &:hover {
    background-color: #ebf5ff !important;
    color: #444 !important;
  }

  &.saturday:hover {
    background-color: #ebf5ff !important;
    color: #ff5252 !important;
  }
}

.public_holiday:hover:not(.school_holiday) {
  background-color: #ebf5ff !important;
  color: #ff5252 !important;
}

.calendar-container {
  position: relative;
  z-index: 1;
  width: 90%;
  margin: 0 auto 0 auto;
}

.table,
tr,
th,
td {
  font-size: 18px;
  text-align: center;
  border: 1px solid black;
}

.table,
td {
  padding: 25px;
}

.table th {
  line-height: 1.5 !important;
  padding: 10px 0;
}

.head_title {
  background: white;
  color: #333;
}

.table {
  background: white;

  td,
  th {
    color: #444;
    border-color: #e9e9e9;
  }
}

.engDate {
  font-size: 10px;
  position: absolute;
  margin-top: 55px;
  margin-left: 28px;
}

.engMonth {
  font-size: 10px;
  position: absolute;
  margin-top: 55px;
  margin-left: 5px;
}

.tithi {
  position: absolute;
  font-size: 10px;
  margin-left: -65px;
  margin-top: 55px;
}

.event_title {
  position: absolute;
  font-size: 8px;
  margin-left: -51px;
  margin-top: -24px;
  width: 122px;
}
.school_event {
  position: absolute;
  font-size: 10px;
  width: 122px;
}

.saturday {
  /*background: red;*/
  color: #b71c1c;
}

.school_holiday {
  background: #ff5252;
  color: white;
}

.current_day {
  background: green;
  color: white;
}

.nepDate {
  font-size: 35px;
}

.calendar_head {
  font-size: 25px;
}

span.public_holiday {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #ff5252 !important;
  border-radius: 52%;
  display: inline-block;
  margin-left: -70px;
  margin-top: 40px;
}

td.public_holiday {
  color: #ff5252;
}

.public_holiday_notification {
  height: 15px;
  width: 15px;
  background-color: #b71c1c;
  border-radius: 52%;
  display: inline-block;
  margin-right: 10px;
}

.calendar_info {
  margin-top: 25px;
}

.school_event {
  position: absolute;
  margin-top: 42px;
  margin-left: -76px;
  font-size: 10px;
  width: 122px;
}

.school_event_box {
  color: #444 !important;
  background-color: #f9d6a9 !important;
}

/*.tooltip {*/
/*position: relative;*/
/*display: inline-block;*/
/*border-bottom: 1px dotted black; !* If you want dots under the hoverable text *!*/
/*}*/

/* Tooltip text */
.tooltiptext {
  transition: 0.5s;
  opacity: 0;
  max-width: 200px;
  line-height: 1;
  font-size: 13px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 3px;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip_app:hover .tooltiptext {
  opacity: 1;
}

.yearEndClassNext,
.yearEndClassPrevious {
  background: #ccc !important;
  cursor: not-allowed;

  i {
    color: white !important;
  }
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 30px;
}
</style>
